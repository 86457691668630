@import './theme-light.scss';
@import './theme-dark.scss';
@import './material-ui.scss';
@import './refers.scss';

body {
  background-color: var(--primaryBg) !important;
}

.app-name-version {
  font-family: Impact, Charcoal, sans-serif !important;
  color: var(--primaryText) !important;
  text-decoration: none !important;
}

.font-family-custom {
  font-family: Impact, Charcoal, sans-serif !important;
}

.font-size-8px {
  font-size: 18px !important;
}

.font-size-10px {
  font-size: 10px !important;
}

.font-size-12px {
  font-size: 12px !important;
}

.font-size-14px {
  font-size: 14px !important;
}

.font-size-16px {
  font-size: 16px !important;
}

.font-size-18px {
  font-size: 18px !important;
}

.font-size-20px {
  font-size: 20px !important;
}

.App {
  text-align: center;
}

.app-text-color {
  color: var(--primaryText) !important;
}

.text-align-left {
  text-align: left;
}

.display-block {
  display: block;
}

.object-hidden {
  visibility: hidden;
  display: none;
}

.text-size-3vmin {
  font-size: 3vmin !important;
}

.text-size-5vmin {
  font-size: 5vmin !important;
}

.text-size-7vmin {
  font-size: 7vmin !important;
}

.text-size-1vw {
  font-size: 1vw !important;
}

.text-size-2vw {
  font-size: 2vw !important;
}

.text-size-3vw {
  font-size: 3vw !important;
}

.text-size-5vw {
  font-size: 5vw !important;
}

.App-header {
  background-color: var(--primaryBg);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  // font-size: calc(10px + 2vmin);
  color: var(--primaryText);
}

.ContainerMainCustom {
  margin-top: 75px;
}

.box-shadow-custom {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.overflow-scrolling-touch {
  -webkit-overflow-scrolling: touch;
}

.icon-button {
  color: inherit !important;
  outline: none !important;
}

.avatar-button {
  // padding-top: 0px !important;
  // padding-bottom: 0px !important;
  padding: 8px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.MuiAppBar-color-custom {
  color: var(--AppBarTextColorCustom) !important;
  background-color: var(--AppBarBgColorCustom) !important;
}

.App-logo {
  height: 35vmin;
  pointer-events: none;
}

.Login-logo {
  height: 25vmin;
  pointer-events: none;
}

.Login-dialog-custom {
  max-width: 350px;
}

.margin-bottom-2px {
  margin-bottom: 2px !important;
}

.checkbox-visit {
  padding-left: 0px !important;
  padding-right: 8px !important;
}

.margin-bottom-4px {
  margin-bottom: 4px !important;
}

.margin-bottom-6px {
  margin-bottom: 6px !important;
}

.margin-bottom-8px {
  margin-bottom: 8px !important;
}

.margin-bottom-10px {
  margin-bottom: 10px !important;
}

.margin-bottom-12px {
  margin-bottom: 12px !important;
}

.margin-bottom-14px {
  margin-bottom: 14px !important;
}

.margin-bottom-0px {
  margin-bottom: 0px !important;
}

.margin-top-4px {
  margin-top: 4px !important;
}

.margin-top-8px {
  margin-top: 8px !important;
}

.margin-top-bottom-0px {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.padding-top-bottom-0px {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.margin-left-right-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.app-backdrop {
  color: #fff !important;
  z-index: 999999999 !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.app-name {
  margin: auto;
}

.app-tool-bar {
  min-height: 56px !important;
}

.app-name-text {
  padding-left: 24px;
  color: #e83d84;
  margin-bottom: 0px;
}

.app-name-text-bg-color {
  // background-color: currentColor;
  background-color: var(--appNameTextBgColor);
}

.navbar-icon {
  position: fixed;
  left: -24px;
}

.navbar-left {
  width: 250px;
}

.toggle-icon-custom {
  left: 228px;
  z-index: 1220;
}

.overflow-x-auto {
  overflow: 'auto';
}

//$foreground-color: #171d21;
// $background-color: #1d2f3c;

// media size

@media (max-width: 576px) {
  .mb-xs-2 {
    margin-bottom: 0.5rem !important;
  }
}

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white, 50%)) {
  ::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  ::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    background: $background-color;
    border-radius: 10px;
  }

  // For Internet Explorer
  body {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}

@include scrollbars(0.4em, rgba(24, 24, 24, 0.95));

.navbar-scrollbar-color::-webkit-scrollbar {
  width: 0.4em;
  height: 0.4em;
}

.navbar-scrollbar-color::-webkit-scrollbar-track {
  --webkit-box-shadow: inset 0 0 6px rgba(250, 250, 250, 0.3);
  background: rgba(255, 255, 255, 0.3);
  border-radius: 0px;
}

.navbar-scrollbar-color::-webkit-scrollbar-thumb {
  border-radius: 0px;
  --webkit-box-shadow: inset 0 0 6px rgba(250, 250, 250, 0.5);
  background: rgba(000, 000, 000, 0.3);
}

hr {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

/* container main */

.container-main {
  color: var(--primaryText);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 45px;
  text-align: center;
  overflow-x: hidden;
  word-wrap: break-word;
}

/* Left Menu */
.left-menu {
  min-width: 250px;
  color: var(--AppBarTextColorCustom) !important;
  background-color: var(--AppBarBgColorCustom) !important;

  a {
    color: var(--AppBarTextColorCustom) !important;
  }

  .MuiPaper-root {
    background-color: var(--AppBarBgColorCustom) !important;
  }

  .MuiListItemIcon-root {
    color: var(--AppBarTextColorCustom) !important;
  }

  .MuiMenu-list {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .MuiListItem-root {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .MuiListItem-button:hover {
    text-decoration: none !important;
    background-color: var(--MuiListItemButtonHover) !important;
  }

  .ListItem-active {
    background-color: rgba(255, 255, 255, 0.05) !important;
    color: var(--ListItemActiveText) !important;

    path {
      color: var(--ListItemActiveText) !important;
    }
  }
}

/* visit menu */
.visit-menu-custom {
  .MuiMenu-paper {
    max-height: calc(100% - 20px) !important;
    -webkit-overflow-scrolling: touch;
  }

  .MuiListItem-button:hover {
    background-color: rgba(0, 64, 133, 0.5) !important;
    color: #fff !important;
  }
}

/* Profile Menu */
.profile-menu {
  min-width: 250px;
  color: var(--AppBarTextColorCustom) !important;
  // background-color: var(--AppBarBgColorCustom) !important;

  a {
    color: var(--AppBarTextColorCustom) !important;
  }

  .MuiPaper-root {
    background-color: var(--AppBarBgColorCustom) !important;
  }

  .MuiListItemIcon-root {
    color: var(--AppBarTextColorCustom) !important;
  }

  .MuiMenu-list {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .MuiListItem-root {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}

/* App Menu */
.app-menu {
  min-width: 252px;
  min-height: 192px;
  color: var(--primaryText) !important;

  a {
    color: var(--primaryText) !important;
  }

  .avatar-app-icon {
    background-color: var(--primaryTheme) !important;
    width: 50px;
    height: 50px;
  }

  .MuiPaper-root {
    color: var(--primaryText) !important;
    background-color: var(--AppBarBgColorCustom) !important;
  }

  .MuiListItemIcon-root {
    color: var(--primaryText) !important;
  }

  .MuiMenu-list {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .MuiListItem-root {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .MuiListItem-button:hover {
    text-decoration: none !important;
    background-color: var(--MuiListItemButtonHover) !important;
    border-radius: 4px;
  }
}

/* App Notify */
.app-notify {
  min-width: 252px;
  // min-height: 192px;
  color: var(--primaryText) !important;

  a {
    color: var(--primaryText) !important;
  }

  .MuiTypography-colorTextPrimary {
    color: var(--primaryText) !important;
  }

  .avatar-app-icon {
    background-color: var(--primaryTheme) !important;
    width: 50px;
    height: 50px;
  }

  .MuiPaper-root {
    color: var(--primaryText) !important;
    background-color: var(--NotifyBgColorCustom) !important;
  }

  .MuiListItemIcon-root {
    color: var(--primaryText) !important;
  }

  .MuiMenu-list {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .MuiListItem-root {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  /*
    .MuiListItem-button:hover {
        text-decoration: none !important;
        background-color: var(--MuiListItemButtonHover) !important;
        border-radius: 4px;
        ;
    }
*/
  .MuiListItem-button-bg-default:hover {
    text-decoration: none !important;
    background-color: var(--MuiListItemButtonHover) !important;
    border-radius: 4px;
  }

  .MuiListItem-button-bg-alert:hover {
    text-decoration: none !important;
    background-color: rgba(253, 179, 116, 0.8) !important;
    border-radius: 4px;
  }

  .MuiListItem-button-bg-alert {
    color: rgb(0, 0, 0);
    background-color: #fdba7f !important;
  }
}

/* patient-main */
.patient-main {
  .text-color {
    color: var(--primaryText) !important;
  }

  a {
    color: var(--AppBarTextColorCustom) !important;
  }
}

/* patient-data */
.patient-data {
  color: #e6e6e6;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.patient-data-primary {
  background-color: rgba(51, 122, 183, 0.9) !important;
  border-color: #337ab7 !important;
}

.patient-data-danger {
  background-color: rgba(183, 51, 51, 0.9) !important;
  border-color: #b73333 !important;
}

.font-color-alert {
  color: #ebff00 !important;
}

.pt-image {
  background-size: cover !important;
  width: 110px !important;
  height: 130px !important;
  background-position: center top !important;
}

/* visits-data */

.visits-data-primary {
  color: #004085 !important;
  background-color: #b8daff !important;
}

.icon-visit {
  width: 30px !important;
  height: 30px !important;
  margin-right: 4px !important;
  float: left !important;
}

.visits-data-active {
  color: #fff !important;
  background-color: #004085 !important;
  border-color: #004085 !important;
}

.MuiTabs-centered-custom {
  color: var(--AppBarTextColorCustom) !important;
  background-color: var(--AppBarBgColorCustom) !important;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);

  .MuiTab-textColorPrimary {
    color: var(--AppBarTextColorCustom) !important;
  }
}

.TabPanel-custom {
  background-color: var(--primaryBg) !important;
  color: var(--primaryText) !important;

  .MuiBox-root {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  .card-custom {
    background-color: var(--primaryBg) !important;
    color: var(--primaryText) !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
}

/* visit-detail */
.visit-detail-card {
  background-color: var(--secondaryBg) !important;
  color: var(--primaryText) !important;
  padding: 8px;
}

/* refer-custom */
.referrals-card {
  background-color: #f8f4a5 !important;
  border-color: rgb(250, 241, 79) !important;
  color: #504c00 !important;
  padding: 8px;

  .MuiTypography-colorTextSecondary {
    color: #504c00 !important;
  }

  .card-header {
    color: #504c00 !important;
  }

  .MuiIconButton-label {
    color: #504c00 !important;
  }
}

.referrals-card-complete {
  background-color: rgb(186, 248, 171) !important;
  border-color: rgb(98, 221, 70) !important;
  color: rgb(0, 58, 8) !important;
  // padding: 8px;

  .MuiTypography-colorTextSecondary {
    color: rgb(0, 58, 8) !important;
  }

  .card-header {
    color: rgb(0, 58, 8) !important;
  }

  .MuiIconButton-label {
    color: rgb(0, 58, 8) !important;
  }
}

.referrals-card-rejected {
  background-color: rgb(255, 155, 164) !important;
  border-color: rgb(241, 76, 91) !important;
  color: rgb(100, 0, 8) !important;
  // padding: 8px;

  .MuiTypography-colorTextSecondary {
    color: rgb(100, 0, 8) !important;
  }

  .card-header {
    color: rgb(100, 0, 8) !important;
  }

  .MuiIconButton-label {
    color: rgb(100, 0, 8) !important;
  }
}

.refer-text-custom {
  h6 {
    font-size: 14px;
  }
}

.MuiButton-containedPrimary {
  .MuiButton-label {
    color: #fff !important;
  }
}

/* app offline */
.app-offline {
  background-color: #fc9842;
  background-image: linear-gradient(315deg, #fc9842 0%, #fe5f75 74%);
  text-align: center;
  font-size: 16px;
  border-radius: 4px;
  position: fixed;
  z-index: 999999999;
  padding-left: 10px;
  padding-right: 10px;
  min-width: 340px;
  max-width: 340px;
}

/* user online */
.user-online {
  background-color: #00b712;
  background-image: linear-gradient(315deg, #00b712 0%, #5aff15 74%);
  text-align: center;
  font-size: 14px;
  position: fixed;
  z-index: 99999999;
  border-radius: 4px;
  color: #111;
  line-height: 22px;
  padding-left: 10px;
  padding-right: 10px;
  min-width: 200px;
  max-width: 200px;
}

/** emt-class */
.emt-class {
  border: 0px;
  overflow-y: auto;
  overflow-x: auto;
  margin-left: auto;
  margin-right: auto;
}

/** emt-class */
.geospatial-outbreak-class {
  border: 0px;
  overflow-y: auto;
  overflow-x: auto;
  margin-left: auto;
  margin-right: auto;
}

.version-text {
  text-align: center;
  opacity: 0.5;
}

.modal-dialog {
  margin-top: 10%;
}
